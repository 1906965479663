import ROUTER_CONFIG from './prod-router'
export default {
  DEFAULT_ACTIVE: 'cthealth',
  HERO_CONFIG: {
    // corona: {
    //   id: 'link_homepage_widget_corona',
    //   mobileId: 'link_homepage_widget_corona_mobile',
    //   navLinkId: 'link_header_corona',
    //   navLinkDisplay: 'Asuransi Corona',
    //   active: true,
    //   path: '/corona',
    //   category: 'corona',
    //   iconCss: 'fas fa-viruses',
    //   titleId: 'CORONA',
    //   titleClassName: 'fas fa-viruses text-primary fs-14',
    //   mobileViewCss: 'fs-14',
    //   bannerImage: 'https://storage.googleapis.com/pp_img/website/home-corona.jpg',
    //   mobileBannerImage: 'https://storage.googleapis.com/pp_img/website/home-corona-mobile.jpg',
    //   labelGif: 'https://storage.googleapis.com/pp_static_staging/new_label.gif',
    //   labelGifClassName: 'new-label-header-home',
    //   labelGifMobile: 'https://storage.googleapis.com/pp_static_staging/new_label_mobile.gif',
    //   labelGifMobileClassName: 'new-label-mobile-home'
    // },
    // crackscreen: {
    //   id: 'link_homepage_widget_gaya_hidup',
    //   mobileId: 'link_homepage_widget_gaya_hidup',
    //   navLinkId: 'link_header_Selular',
    //   navLinkDisplay: 'Asuransi Layar Gadget',
    //   active: true,
    //   path: '/crackscreen',
    //   category: 'crackscreen',
    //   iconCss: 'fas fa-mobile',
    //   titleId: 'LAYAR GADGET',
    //   titleClassName: 'fas fa-mobile text-primary fs-22',
    //   mobileViewCss: 'fs-22',
    //   bannerImage: 'https://storage.googleapis.com/pp_img/website/home-lifestyle.jpg',
    //   mobileBannerImage: 'https://storage.googleapis.com/pp_img/website/home-lifestyle.jpg'
    // },
    cthealth: {
      id: 'link_homepage_widget_health',
      mobileId: 'link_homepage_widget_health_mobile',
      navLinkId: 'link_header_health',
      navLinkDisplay: 'Asuransi Kesehatan',
      active: true,
      path: '/cthealth',
      category: 'cthealth',
      iconCss: 'fas fa-hand-holding-medical',
      titleId: 'KESEHATAN',
      titleClassName: 'fas fa-hand-holding-medical text-primary fs-22',
      mobileViewCss: 'fs-22',
      bannerImage: 'https://storage.googleapis.com/pp_img/website/home-corona.jpg',
      mobileBannerImage: 'https://storage.googleapis.com/pp_img/website/home-corona-mobile.jpg'
    },
    starHealth: {
      id: 'link_homepage_widget_starHealth',
      mobileId: 'link_homepage_widget_starHealth_mobile',
      navLinkId: 'link_header_starHealth',
      navLinkDisplay: 'Asuransi Jiwa',
      active: true,
      path: '/starhealth',
      category: 'starHealth',
      iconCss: 'fas fa-heartbeat',
      titleId: 'JIWA',
      titleClassName: 'fas fa-heartbeat text-primary fs-22',
      mobileViewCss: 'fs-22',
      bannerImage: 'https://storage.googleapis.com/pp_img/website/home-starHealth.png',
      mobileBannerImage: 'https://storage.googleapis.com/pp_img/website/home-starHealth.png',
      labelGif: 'https://storage.googleapis.com/pp_static_staging/new_label.gif',
      labelGifClassName: 'new-label-header-home',
      labelGifMobile: 'https://storage.googleapis.com/pp_static_staging/new_label_mobile.gif',
      labelGifMobileClassName: 'new-label-mobile-home'
    }
    // Bike: {
    //   id: 'link_homepage_widget_bike',
    //   mobileId: 'link_homepage_widget_bike_mobile',
    //   navLinkId: 'link_header_bike',
    //   navLinkDisplay: 'Asuransi Motor',
    //   active: true,
    //   path: '/bike',
    //   category: 'bike',
    //   iconCss: 'fas fa-motorcycle',
    //   titleId: 'MOTOR',
    //   titleClassName: 'fas fa-motorcycle text-primary fs-22',
    //   mobileViewCss: 'fs-22',
    //   bannerImage: 'https://storage.googleapis.com/pp_img/website/bike/home-bike.png',
    //   mobileBannerImage: 'https://storage.googleapis.com/pp_img/website/bike/home-bike.png',
    //   labelGif: 'https://storage.googleapis.com/pp_static_staging/new_label.gif',
    //   labelGifClassName: 'new-label-header-home',
    //   labelGifMobile: 'https://storage.googleapis.com/pp_static_staging/new_label_mobile.gif',
    //   labelGifMobileClassName: 'new-label-mobile-home'
    // }
  },
  ROUTER_CONFIG,
  SHOW_AVA_POPUP: false
}
