import React, { Component, Suspense } from 'react'
import { Route, Switch } from 'react-router-dom'
import { ConnectedRouter } from 'react-router-redux'
import { connect } from 'react-redux'
import AppHeader from './components/Header'
import { Layout } from 'antd'
import Page404 from './containers/StaticPages/404'
import config from './config'

const { ROUTER_CONFIG } = config

const { Content } = Layout

class PublicRoutes extends Component {
  render () {
    const { overlay, history, LanguageSwitcher, onLocaleChange, locale } = this.props
    return (
      <ConnectedRouter history={history}>
        <Layout>
          {overlay}
          <AppHeader onLocaleChange={onLocaleChange} locale={locale} />
          <Content >
            <Suspense fallback={<div />}>
              <Switch>
                {
                  locale === 'vn'
                    ? (ROUTER_CONFIG.slice(0, 1).map(({ exact, path, component }) => (
                      <Route exact={exact} key={path} path={path} component={component} />
                    )))
                    : (
                      ROUTER_CONFIG.map(({ exact, path, component }) => (
                        <Route exact={exact} key={path} path={path} component={component} />
                      ))
                    )
                }
                <Route component={Page404} />
              </Switch>
            </Suspense>
          </Content>
        </Layout>
      </ConnectedRouter>
    )
  }
}

export default connect(state => ({
  overlay: state.App.overlay,
  router: state.router,
  LanguageSwitcher: state.LanguageSwitcher.language
}), {})(PublicRoutes)
