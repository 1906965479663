import styled from 'styled-components'
import theme from '../../../theme'

export const MobileLanguageChangeStyleWrapper = styled.div`
  display: none;
  position: absolute;
  bottom: 0;
  padding: 40px 30px;
  & > ul {
    display: flex;
    & > li:not(:last-child) {
      margin-right: 24px;
    }
  }
  .flag-container {
    width: auto;
    height: 35px;
    display: flex;
    gap: 15px;
    border-radius: 50%;
    opacity: 0.5;
    & > img {
      width: 100%;
      height: 100%;
    }
  }
  .flag-container.active {
    opacity: 1;
  }
  .flag-container-text {
   
  }
  .flag-container-text.active {
    color: ${theme.color.darkPrimary};
  }
  @media (max-width: 800px) {
    display: block;
  }
`