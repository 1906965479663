import styled from 'styled-components'
import theme from '../theme'

export const StyledHeader = styled.div`
  margin-top: 70px;

  .navbar__menu {
    display: inline-block;
    position: absolute;
    top: 50px;
    left: 0;
    background-color: #fad200;
    border-radius: 0px 0px 10px 10px;
    padding: 15px;
  }

  .navbar__content {
    position: relative;
    &:before {
      position: absolute;
      top: -6px;
      border-left: 8px solid transparent;
      border-right: 8px solid transparent;
      border-bottom: 6px solid #fad200;
    }
  }

  .navbar__dropdown-text {
    display: inline-block;
    -webkit-text-decoration: none;
    text-decoration: none;
    color: #666;
    font-size: 0.8em;
    font-weight: 400;
  }

  .navbar__dropdown-text:hover::before {
    transform: translateX(-50%);
    border-radius: 100%;
    position: absolute;
    background: #fad200;
    bottom: 0px;
    height: 5px;
    content: '';
    width: 5px;
    left: 50%;
  }

  .navbar__dropdown-icon {
    display: inline-block;
    transform: rotate(270deg);
    margin-left: 10px;
    width: 7px;
    transition: opacity 0.2s;

    &--active {
      opacity: 0;
    }
  }

  .navbar__dropdown {
    display: inline-block;
    position: relative;
    padding: 12px;
  }

  .navbar__claim-ctn{
    padding: 14px 90px 14px 90px;
  }

  .navbar__claim-button {
    width: 170px;
    height: 48px;
    border-radius: 8px;
    padding: 14px 20px 14px 20px;
    border-radius: 32px;
    background: #FFC800;
    font-size: 16px;
    font-weight: 800;
    line-height: 20px;
    text-align: center;
    max-width: 170px;
  }

  .navbar__dropdown-item {
    display: inline-block;
    border-radius: 20px;
    width: 100%;
    white-space: nowrap;

    &:hover {
      background-color: white;
    }

    &--selected {
      background-color: white;
    }
  }

  .nav {
    height: auto;
    width: 100%;
    background-color: #fff;
    position: relative;
    display: flex;
    position: fixed;
    top: 0;
    z-index: 2;
  }

  .nav > .nav-header {
    float: left;
    padding: 10px 40px;
    display: inline;
  }

  .nav > .nav-header > .nav-title {
    display: inline-block;
    font-size: 22px;
    color: #fff;
    padding: 8px 12px;
    width: 200px;
  }
  .nav > .nav-header > .nav-title img {
    max-width: 200px;
    height: 30px;
    object-fit: contain;
  }

  .nav > .nav-btn {
    display: none;
  }

  .spaceBetween{
      justify-content: space-between;
    }
    .end{
      justify-content: flex-end !important;
    }

  .nav > .nav-links {
    display: inline;
    float: left;
    font-size: 18px;
    padding: 10px 20px;
    width: calc(100% - 240px);
    display: flex;
    justify-content: space-between;
  }
  .nav > .nav-links-mobile{
    display: none;
  }
  .nav > .nav-links > .nav-left { 
    float: left;   
    width: 100%;
    display: flex;
    justify-content: center;
    padding-left: 100px;
  }
  .nav > .nav-links > .nav-right {
    float: right;
    width; 12%;
  }

  .nav > .nav-links > .navbar a {
    display: inline-block;
    padding: 12px;
    text-decoration: none;
    color: #666666 !important;
    font-size: 0.8em;
    font-weight: 600;
    position: relative;
  }
  .nav-bar-hover {
    visibility: hidden;
    display: block;
    line-height: 0;
    height: 0;
    border: 2px solid #fad200;
    width: 1px;
    text-align: center;
    border-radius: 50%;
    position: absolute;
    left: calc(50% - 1px);
  }

  .nav > .nav-links > .navbar a.nav-bar-selcted {
    color: #fad200 !important;
  }

  .nav > .nav-links > .navbar a:hover > .nav-bar-hover {
    visibility: visible;
  }

  .nav > #nav-check {
    display: none;
  }

  .textLogo img {
    vertical-align: middle;
    width: 18px;
    margin-right: 5px;
  }
  .textLogo.big img {
    width: 25px;
  }
  .textLogo .text {
    vertical-align: middle;
    font-weight: 300;
    color: #999999;
  }
  .lightArrow {
    color: #cccccc;
    font-size: 10px;
    vertical-align: middle;
    margin-left: 5px;
  }
  .mobile {
    visibility: hidden;
  }
  .mobileRight {
    float: none;
  }

  .dropdown {
    position: relative;
    display: inline-block;
  }

  .dropdown-content {
    display: none;
    position: absolute;
    background-color: #ffffff;
    min-width: 160px;
    box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
    z-index: 1;
  }
  .dropdown .dropdown-content.dropdown-right {
    right: 0;
  }

  .dropdown-content a {
    width: 100%;
    color: #999999 !important;
  }

  .dropdown.toggled .dropdown-content {
    display: block;
  }

  .flotRight {
    float: right;
  }

  @media (max-width: 800px) {
    .navbar__menu {
      position: unset;
      display: block;
      background-color: unset;
    }

    .navbar__content {
    }

    .navbar__dropdown-text {
      color: #000;
    }

    .navbar__dropdown-icon {
      content: url('https://storage.googleapis.com/pp_img/website/blog/blogs-arrow-sm.svg');
    }

    .navbar__dropdown {
      padding: 15px 30px;
      display: block;
      width: 100%;
    }
    .navbar__claim-ctn{
      display: none;
    }

    .navbar__claim-button {
      display: none;
    }

    .navbar__dropdown-item {
      color: #333;

      &--selected {
        background-color: unset;
        color: #fff;
      }
    }

    .nav > .nav-btn {
      display: inline-block;
      position: absolute;
      right: 0px;
      top: 0px;
      left: auto;
      padding: 10px;
      z-index: 11;
    }
    .nav > .nav-btn > label {
      display: inline-block;
      width: 50px;
      height: 50px;
      padding: 13px;
    }
    .nav #nav-check:checked ~ .nav-btn > label {
      background-color: ${theme.color.white};
    }
    .nav > .nav-btn > label > span {
      display: block;
      width: 25px;
      height: 10px;
      border-top: 3px solid #000;
    }
    .nav #nav-check ~ .nav-btn > label > span {
      transition: transform 1s cubic-bezier(0.77, 0.2, 0.05, 1),
        background 1s cubic-bezier(0.77, 0.2, 0.05, 1),
        opacity 0.55s ease-in-out;
    }
    .nav #nav-check:checked ~ .nav-btn > label > span:nth-last-child(3) {
      opacity: 0;
      transform: rotate(0deg) scale(0.2, 0.2);
    }
    .nav #nav-check:checked ~ .nav-btn > label > span:nth-last-child(2) {
      transform: rotate(-45deg) translate(0px, -1px);
      border-color: ${theme.color.mineShaft};
    }
    .nav #nav-check:checked ~ .nav-btn > label > span:nth-last-child(1) {
      transform: rotate(45deg) translate(-11px, -4px);
      border-color: ${theme.color.mineShaft};
    }
    .nav > .nav-links {
      position: fixed;
      display: block;
      width: 100%;
      background-color: #ffffff;
      height: 0px;
      overflow-y: hidden;
      top: 0px;
      right: 0px;
      left: 0px;
      z-index: 10;
      visibility: hidden;
    }
    .nav > .nav-links-mobile {
      position: fixed;
      display: block;
      width: 100%;
      background-color: #ffffff;
      top: 0px;
      right: 0px;
      left: 0px;
      z-index: 10;
      display: none;
      height: 30vh;
    }
    .nav > .nav-links-mobile > .nav-mobile-top {
      width: 300px;
      height: 100px;
      padding: 22px 32px;
    }
    .nav > .nav-links-mobile > .nav-mobile-middle {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      gap: 20px;
    }
    .nav > .nav-links-mobile > .nav-mobile-bottom {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: center;
    }
    .nav > .nav-links > .navbar a {
      padding: 15px 30px;
      display: block;
      width: 100%;
      color: #000 !important;
    }
    .nav > .nav-links > .nav-left {
      flex-direction: column;
    }

    .nav > .nav-links > .navbar a.nav-bar-selcted {
      color: ${theme.color.white} !important;
    }
    a.nav-bar-selcted {
      color: #fad200 !important;
    }

    .nav > .nav-links > .nav-left,
    .nav > .nav-links > .nav-right {
      float: none;
    }

    // .nav > #nav-check:not(:checked) ~ .nav-links {
    //   height: 0px;
    //   width: 0px;
    // }

    .nav > #nav-check:checked ~ .nav-links {
      width: 100vw;
      height: 0vh;
      overflow-y: auto;
      visibility: visible;
    }
    .nav > #nav-check:checked ~ .nav-links-mobile {
      width: 100vw;
      height: 100vh;
      overflow-y: auto;
      display: block;
    }
    .mobile {
      visibility: visible;
    }
    .desktop {
      display: none;
    }
    .mobileRight {
      float: right;
    }
    .dropdown {
      width: 100%;
    }
    .nav > .nav-links > .navbar a .dropdown-content ul li a {
      width: 100%;
      color: #999999 !important;
    }
    .dropdown-content {
      box-shadow: none;
    }
    .dropdown:hover .dropdown-content {
      position: static;
    }
    .dropdown-content ul {
      padding-left: 20px;
    }
  }
`
