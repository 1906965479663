import { lazy } from 'react'

const HomeComponent = lazy(() => import('../containers/Home'))
// const CoronaMain = lazy(() => import('../containers/WebsiteProducts/Corona/Main'))
// const CoronaPackages = lazy(() => import('../containers/WebsiteProducts/Corona/PackagesPage'))
// const CoronaDetails = lazy(() => import('../containers/WebsiteProducts/Corona/DetailsPage'))
// const CoronaSummary = lazy(() => import('../containers/WebsiteProducts/Corona/Summary'))
// const CtHealthMain = lazy(() => import('../containers/WebsiteProducts/CtHealth/Main'))
// const CtHealthPackages = lazy(() => import('../containers/WebsiteProducts/CtHealth/PackagesPage'))
// const CtHealthDetails = lazy(() => import('../containers/WebsiteProducts/CtHealth/DetailsPage'))
// const CtHealthSummary = lazy(() => import('../containers/WebsiteProducts/CtHealth/Summary'))
// const StarHealthMain = lazy(() => import('../containers/WebsiteProducts/StarHealth'))
// const StarHealthDetails = lazy(() => import('../containers/WebsiteProducts/StarHealth/detail'))
// const BikeMain = lazy(() => import('../containers/WebsiteProducts/Bike'))
// const BikeDetails = lazy(() => import('../containers/WebsiteProducts/Bike/detail'))

const website = {
  payment: {
    success: lazy(() => import('../containers/WebsiteProducts/Payment/Success')),
    failure: lazy(() => import('../containers/WebsiteProducts/Payment/Pending')),
    pending: lazy(() => import('../containers/WebsiteProducts/Payment/Failure')),
    processing: lazy(() => import('../containers/WebsiteProducts/Payment/Processing'))
  },
  // crackscreen: {
  //   home: lazy(() => import('../containers/WebsiteProducts/CrackScreenWeb')),
  //   details: lazy(() => import('../containers/WebsiteProducts/CrackScreenWeb/DetailsPage')),
  //   summary: lazy(() => import('../containers/WebsiteProducts/CrackScreenWeb/Summary'))
  // },
  staticPage: {
    career: lazy(() => import('../containers/StaticPages/Career')),
    jobVacancy: lazy(() => import('../containers/StaticPages/Career/jobVacancy')),
    jobCategory: lazy(() => import('../containers/StaticPages/Career/jobCategory')),
    jobDescription: lazy(() => import('../containers/StaticPages/Career/jobDescription')),
    careerForm: lazy(() => import('../containers/StaticPages/Career/formSubmit')),
    claim: lazy(() => import('../containers/StaticPages/Claim')),
    about: lazy(() => import('../containers/StaticPages/AboutUs')),
    faq: lazy(() => import('../containers/StaticPages/Faq2/index')),
    terms: lazy(() => import('../containers/StaticPages/Terms')),
    privacy: lazy(() => import('../containers/StaticPages/PrivacyPolicy')),
    dictionary: lazy(() => import('../containers/StaticPages/Dictionary')),
    maintenance: lazy(() => import('../containers/StaticPages/Maintenance'))
    // blog: {
    //   home: lazy(() => import('../containers/StaticPages/Blog')),
    //   detail: {
    //     pluang: lazy(() => import('../containers/StaticPages/Blog/details/01pluang')),
    //     insuretech: lazy(() => import('../containers/StaticPages/Blog/details/02insuretech')),
    //     sompoInsurancePromoRamadan: lazy(() => import('../containers/StaticPages/Blog/details/03sompoInsurancePromoRamadan')),
    //     peranInsurtech: lazy(() => import('../containers/StaticPages/Blog/details/04peranInsurtech')),
    //     employeeBenefitAdalah: lazy(() => import('../containers/StaticPages/Blog/details/05employeeBenefitAdalah')),
    //     asuransiKesehatan: lazy(() => import('../containers/StaticPages/Blog/details/06asuransiKesehatan')),
    //     asuransiJiwa: lazy(() => import('../containers/StaticPages/Blog/details/07asuransiJiwa')),
    //     asuransiKendaraan: lazy(() => import('../containers/StaticPages/Blog/details/08asuransiKendaraan')),
    //     asuransiProperti: lazy(() => import('../containers/StaticPages/Blog/details/09asuransiProperti')),
    //     bedaAsuransiJiwaDanAsuransiKesehatan: lazy(() => import('../containers/StaticPages/Blog/details/10bedaAsuransiJiwaDanAsuransiKesehatan')),
    //     caraBangunTidurYangBenar: lazy(() => import('../containers/StaticPages/Blog/details/11caraBangunTidurYangBenar')),
    //     caraMandiPagiDenganAirDinginYangBenar: lazy(() => import('../containers/StaticPages/Blog/details/12caraMandiPagiDenganAirDinginYangBenar')),
    //     SarapanSehatDanLeza: lazy(() => import('../containers/StaticPages/Blog/details/13SarapanSehatDanLeza')),
    //     berapaLangkahKakiHarianIdeal: lazy(() => import('../containers/StaticPages/Blog/details/14berapaLangkahKakiHarianIdeal')),
    //     panduanPosisiDudukYangNyamanSaatBekerja: lazy(() => import('../containers/StaticPages/Blog/details/15panduanPosisiDudukYangNyamanSaatBekerja')),
    //     MakananYangAmpuhTingkatkanImunDanCegahCorona: lazy(() => import('../containers/StaticPages/Blog/details/16MakananYangAmpuhTingkatkanImunDanCegahCorona'))
    //   }
    // }
  },
  greetings: {
    greeting: lazy(() => import('../containers/Greeting'))
  }
}

const experimental = {
  safedrive: lazy(() => import('../containers/StaticPages/Safedrive')),
  appfaq: lazy(() => import('../containers/StaticPages/AppFaq'))
}

export default [
  {
    exact: true,
    path: '/',
    component: HomeComponent
  },
  {
    exact: true,
    path: '/home',
    component: HomeComponent
  },
  {
    exact: true,
    path: '/payment/success',
    component: website.payment.success
  },
  {
    exact: true,
    path: '/payment/failure',
    component: website.payment.failure
  },
  {
    exact: true,
    path: '/payment/pending',
    component: website.payment.pending
  },
  {
    exact: true,
    path: '/payment/processing',
    component: website.payment.processing
  },
  {
    exact: true,
    path: '/career',
    component: website.staticPage.career
  },
  {
    exact: true,
    path: '/career/job-category',
    component: website.staticPage.jobCategory
  },
  {
    exact: true,
    path: '/career/vacancy-list',
    component: website.staticPage.jobVacancy
  },
  {
    exact: true,
    path: '/career/vacancy-list/job-description',
    component: website.staticPage.jobDescription
  },
  {
    exact: true,
    path: '/career/form',
    component: website.staticPage.careerForm
  },
  {
    exact: true,
    path: '/claim',
    component: website.staticPage.claim
  },
  {
    exact: true,
    path: '/about',
    component: website.staticPage.about
  },
  {
    exact: true,
    path: '/faq',
    component: website.staticPage.faq
  },
  {
    exact: true,
    path: '/terms',
    component: website.staticPage.terms
  },
  {
    exact: true,
    path: '/privacy',
    component: website.staticPage.privacy
  },
  {
    exact: true,
    path: '/dictionary',
    component: website.staticPage.dictionary
  },
  // {
  //   exact: true,
  //   path: '/crackscreen',
  //   component: website.crackscreen.home
  // },
  // {
  //   exact: true,
  //   path: '/crackscreen/details',
  //   component: website.crackscreen.details
  // },
  // {
  //   exact: true,
  //   path: '/crackscreen/summary',
  //   component: website.crackscreen.summary
  // },

  // product section
  // {
  //   exact: true,
  //   path: '/corona',
  //   component: CoronaMain
  // },
  // {
  //   exact: true,
  //   path: '/corona/packages',
  //   component: CoronaPackages
  // },
  // {
  //   exact: true,
  //   path: '/corona/details',
  //   component: CoronaDetails
  // },
  // {
  //   exact: true,
  //   path: '/corona/summary',
  //   component: CoronaSummary
  // },
  // {
  //   exact: true,
  //   path: '/cthealth',
  //   component: CtHealthMain
  // },
  // {
  //   exact: true,
  //   path: '/cthealth/packages',
  //   component: CtHealthPackages
  // },
  // {
  //   exact: true,
  //   path: '/cthealth/details',
  //   component: CtHealthDetails
  // },
  // {
  //   exact: true,
  //   path: '/cthealth/summary',
  //   component: CtHealthSummary
  // },
  // {
  //   exact: true,
  //   path: '/starhealth',
  //   component: StarHealthMain
  // },
  // {
  //   exact: true,
  //   path: '/starhealth/details',
  //   component: StarHealthDetails
  // },
  // {
  //   exact: true,
  //   path: '/bike',
  //   component: BikeMain
  // },
  // {
  //   exact: true,
  //   path: '/bike/details',
  //   component: BikeDetails
  // },
  {
    exact: true,
    path: '/greeetings',
    component: website.greetings.greeting
  },
  {
    exact: true,
    path: '/greeetings',
    component: website.greetings.greeting
  },
  // {
  //   exact: true,
  //   path: '/aftech',
  //   component: experimental.aftech
  // },
  {
    exact: true,
    path: '/join',
    component: experimental.join
  },
  // {
  //   exact: true,
  //   path: '/tia',
  //   component: experimental.tia
  // },
  {
    exact: true,
    path: '/safedrive',
    component: experimental.safedrive
  },
  {
    exact: true,
    path: '/app_faq',
    component: experimental.appfaq
  },
  {
    exact: true,
    path: '/blogs',
    component: website.staticPage.maintenance
  }
  // {
  //   exact: true,
  //   path: '/blog',
  //   component: website.staticPage.blog.home
  // },
  // {
  //   exact: true,
  //   path: '/blog/pluang',
  //   component: website.staticPage.blog.detail.pluang
  // },
  // {
  //   exact: true,
  //   path: '/blog/insuretech',
  //   component: website.staticPage.blog.detail.insuretech
  // },
  // {
  //   exact: true,
  //   path: '/blog/sompo-insurance-promo-ramadan',
  //   component: website.staticPage.blog.detail.sompoInsurancePromoRamadan
  // },
  // {
  //   exact: true,
  //   path: '/blog/peran-insurtech',
  //   component: website.staticPage.blog.detail.peranInsurtech
  // },
  // {
  //   exact: true,
  //   path: '/blog/employee-benefit-adalah',
  //   component: website.staticPage.blog.detail.employeeBenefitAdalah
  // },
  // {
  //   exact: true,
  //   path: '/blog/asuransi-kesehatan',
  //   component: website.staticPage.blog.detail.asuransiKesehatan
  // },
  // {
  //   exact: true,
  //   path: '/blog/asuransi-jiwa',
  //   component: website.staticPage.blog.detail.asuransiJiwa
  // },
  // {
  //   exact: true,
  //   path: '/blog/asuransi-kendaraan',
  //   component: website.staticPage.blog.detail.asuransiKendaraan
  // },
  // {
  //   exact: true,
  //   path: '/blog/asuransi-properti',
  //   component: website.staticPage.blog.detail.asuransiProperti
  // },
  // {
  //   exact: true,
  //   path: '/blog/cara-bangun-tidur-yang-benar',
  //   component: website.staticPage.blog.detail.caraBangunTidurYangBenar
  // },
  // {
  //   exact: true,
  //   path: '/blog/beda-asuransi-jiwa-dan-asuransi-kesehatan',
  //   component: website.staticPage.blog.detail.bedaAsuransiJiwaDanAsuransiKesehatan
  // },
  // {
  //   exact: true,
  //   path: '/blog/cara-mandi-pagi-dengan-air-dingin-yang-benar',
  //   component: website.staticPage.blog.detail.caraMandiPagiDenganAirDinginYangBenar
  // },
  // {
  //   exact: true,
  //   path: '/blog/5-sarapan-sehat-dan-lezat',
  //   component: website.staticPage.blog.detail.SarapanSehatDanLeza
  // },
  // {
  //   exact: true,
  //   path: '/blog/berapa-langkah-kaki-harian-ideal',
  //   component: website.staticPage.blog.detail.berapaLangkahKakiHarianIdeal
  // },
  // {
  //   exact: true,
  //   path: '/blog/panduan-posisi-duduk-yang-nyaman-saat-bekerja',
  //   component: website.staticPage.blog.detail.panduanPosisiDudukYangNyamanSaatBekerja
  // },
  // {
  //   exact: true,
  //   path: '/blog/6-makanan-yang-ampuh-tingkatkan-imun-dan-cegah-corona',
  //   component: website.staticPage.blog.detail.MakananYangAmpuhTingkatkanImunDanCegahCorona
  // }
]
