const theme = {
  font: {
    primary: 'Muli'
  },
  fontSize: {
    xs2: '0.6rem',
    xs: '0.75rem',
    sm: '0.875rem',
    base: '1rem',
    lg: '1.125rem',
    xl: '1.5rem',
    xl2: '1.75rem',
    xl3: '2rem',
    xl4: '2.5rem',
    xl5: '3rem',
    xl6: '4rem'
  },
  fontWeight: {
    hairline: '100',
    thin: '200',
    light: '300',
    normal: '400',
    medium: '500',
    semibold: '600',
    bold: '700',
    extrabold: '800',
    black: '900'
  },
  fontColor: {
    darkGreyHeading: '#4f4f4f',
    darkGreyContent: '#828282',
    veryLightPink2: '#cccccc'
  },
  color: {
    primary: '#fad200',
    black: '#000',
    mediumGray: '#666666',
    lightGray: '#f8f8f8',
    darkPrimary: '#ffc000',

    lightPrimary: '#FFE9EB',
    secondary: '#f99c1e',
    regular: '#828282',
    bold: '#4f4f4f',
    disabled: '#bdbdbd',
    lightPink: '#f5f5f5',
    veryLightPink: '#eeeeee',
    grey: '#999999',
    veryLightGray: '#E5E5E5',
    lightYellow: '#FEFFC9',
    lightestGray: '#EDEDED',
    solitude: '#e1e6f0',
    solitudeLight: '#e1e6f066',
    flamingo: '#EC1B2E',
    mineShaft: '#333333',
    dodgerBlue: '#2369ff',
    hawkesBlue: '#cfd6e8',
    kashmirBlue: '#57678b',
    white: '#ffffff',
    success: '#85b440',
    error: '#ed1b2e',
    pending: '#f99c1e',
    lightBlack: '#4f4f4f',
    lightestWhite: '#ffffff4a',
    tailBlue: '#0ac7d0',
    purple: '#6034bb',
    lightMustard: '#b49600',
    darkGreyv2: '#4B4B4B',
    ppYellow: '#FFC800'
  },
  layout: {
    pageWidth: '100vw',
    headerHeight: '40px',
    tablet: '720px',
    mobile: '450px'
  }
}

export default theme
